import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useState } from "react";

// eslint-disable-next-line react-hooks/exhaustive-deps
const labelMap = {
	astP40: "Assists per 40",
	blk: "Blocks",
	benchPtsPg: "Bench Points Per Game",
	netRtgAdj: "Net Rating - Adjusted",
	paceAdj: "Pace - Adjusted",
	ortgAdj: "Offensive Rating - Adjusted",
	drtgAdj: "Defensive Rating - Adjusted",
	ftPct: "Free Throw Percentage",
	fg3Pct: "Three-Point Percentage",
	fg2Pct: "Two-Point Percentage",
	astRatio: "Assist Ratio",
	tovPg: "Turnovers Per Game",
	blkPg: "Blocks Per Game",
	ptsScoredPg: "Points Scored Per Game",
	sos: "Strength of Schedule",
	rebPg: "Rebounds Per Game",
	ftaPg: "Free Throws Attempted Per Game",
};

const ScatterPlot = ({
	data,
	selectedX,
	selectedY,
	selectedYear,
	showOnlyTourney,
}) => {
	// Function to calculate average
	const calculateAverage = (values) =>
		values.reduce((sum, value) => sum + value, 0) / values.length;

	const baseUrl = "http://localhost:8000/logos/";

	const [selectedTeams, setSelectedTeams] = useState([]);

	const handlePointClick = (point) => {
		setSelectedTeams((prevTeams) => {
			// Check if the team is already in the list to prevent duplicates
			const isExisting = prevTeams.some((team) => team.name === point.name);
			if (!isExisting) {
				return [...prevTeams, { name: point.name, x: point.x, y: point.y }];
			}
			return prevTeams;
		});
	};

	const filterData = (data) => {
		return data
			.filter((item) => item.tourney_gp > 0)
			.map((item) => {
				const showDataLabel = item.tourney_gp > 2;
				return {
					x: item.x,
					y: item.y,
					name: item.label,
					marker: {
						symbol: `url(${baseUrl}${item.teamId}.png)`,
						width: 20,
						height: 20,
					},
					events: {
						click: function () {
							handlePointClick(this);
						},
					},
					dataLabels: {
						enabled: showDataLabel,
						format: "Sweet 16", // Customizes the label. Here, it displays the team's name.
						y: -6,
						style: {
							color: "red", // You can adjust the label color and other properties
							textOutline: "none", // Removes the outline for better readability
						},
					},
				};
			});
	};

	const [chartOptions, setChartOptions] = useState({
		chart: {
			type: "scatter",
			zoomType: "xy",
			reflow: true,
		},
		title: {
			text: "Team Data Scatter Plot",
		},
		xAxis: {
			title: {
				text: labelMap[selectedX] || "X Axis",
			},
		},
		yAxis: {
			title: {
				text: labelMap[selectedY] || "Y Axis",
			},
		},
		tooltip: {
			formatter: function () {
				return `Team: ${this.point.name}<br/>${labelMap[selectedX]}: ${this.x}<br/>${labelMap[selectedY]}: ${this.y}`;
			},
		},
		series: [
			{
				name: "Teams",
				animation: false,
				data: data.map((item) => {
					return {
						x: item[selectedX],
						y: item[selectedY],
						name: item.label,
						marker: {
							symbol: `url(${baseUrl}${item.teamId}.png)`,
							width: 20,
							height: 20,
						},
					};
				}),
			},
		],
	});

	useEffect(() => {
		// Update the chart options when the selected data changes
		setChartOptions((prevOptions) => ({
			...prevOptions,
			title: {
				text: `${labelMap[selectedX] || "X Axis"} vs ${labelMap[selectedY] || "Y Axis"} (Year: ${selectedYear})`,
			},
			xAxis: {
				title: {
					text: labelMap[selectedX] || "X Axis",
				},
			},
			yAxis: {
				title: {
					text: labelMap[selectedY] || "Y Axis",
				},
			},
			tooltip: {
				formatter: function () {
					return `Team: ${this.point.name}<br/>${labelMap[selectedX]}: ${this.x}<br/>${labelMap[selectedY]}: ${this.y}`;
				},
			},
			series: [
				{
					...prevOptions.series[0],
					data: data.map((item) => ({
						x: item.x,
						y: item.y,
						name: item.label,
						marker: {
							symbol: `url(${baseUrl}${item.teamId}.png)`,
							width: 20,
							height: 20,
						},
					})),
				},
			],
		}));
	}, [data, selectedX, selectedY, selectedYear, showOnlyTourney]);

	// For the useEffect hook at line 179
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(
		() => {
			console.log("showOnlyTourney", showOnlyTourney);
			if (showOnlyTourney) {
				setChartOptions((prevOptions) => ({
					...prevOptions,
					title: {
						text: `${labelMap[selectedX] || "X Axis"} vs ${labelMap[selectedY] || "Y Axis"} (Year: ${selectedYear})`,
					},
					series: [
						{
							...prevOptions.series[0],
							data: filterData(data),
						},
					],
				}));
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[data, selectedX, selectedY, selectedYear, showOnlyTourney],
	);

	// For the useEffect hook at line 221
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		// plot the average X and Y values on the chart but on the filtered data for the tournament teams

		let avgX, avgY;

		if (showOnlyTourney) {
			avgX = calculateAverage(filterData(data).map((item) => item.x));
			avgY = calculateAverage(filterData(data).map((item) => item.y));
		} else {
			avgX = calculateAverage(data.map((item) => item.x));
			avgY = calculateAverage(data.map((item) => item.y));
		}

		setChartOptions((prevOptions) => ({
			...prevOptions,
			xAxis: {
				...prevOptions.xAxis,
				plotLines: [
					{
						value: avgX,
						color: "red",
						width: 2,
						label: {
							text: "Average X",
						},
					},
				],
			},
			yAxis: {
				...prevOptions.yAxis,
				plotLines: [
					{
						value: avgY,
						color: "red",
						width: 2,
						label: {
							text: "Average Y",
						},
					},
				],
			},
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, selectedX, selectedY, selectedYear, showOnlyTourney]);

	const clearSelectedTeams = () => {
		setSelectedTeams([]);
	};

	return (
		<div className="scatter-plot-container">
			<div className="scatter-plot">
				<HighchartsReact
					containerProps={{ style: { height: "100%" } }}
					highcharts={Highcharts}
					options={chartOptions}
				/>
			</div>
			<div className="">
				<h3>Selected Teams:</h3>
				<ul>
					{selectedTeams.map((team) => (
						<li key={team.name}>{team.name}</li>
					))}
				</ul>
				<button onClick={clearSelectedTeams} className="clear-teams-btn">
					Clear Teams
				</button>
			</div>
		</div>
	);
};

export default ScatterPlot;
