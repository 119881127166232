export const rounds: RoundProps2[] = [
  {
    title: 'Round Two',
    seeds: [
      {
        "id": 1,
        "teams": [
          { "seed": 1, "name": "North Carolina" },
          { "seed": 9, "name": "Michigan St." }
        ],
        "date": "3/23",
        "time": "5:30 PM"
      },
      {
        "id": 2,
        "teams": [
          { "seed": 4, "name": "Alabama" },
          { "seed": 12, "name": "Grand Canyon" }
        ],
        "date": "3/24",
        "time": "7:10 PM"
      },
      {
        "id": 3,
        "teams": [
          { "seed": 6, "name": "Clemson" },
          { "seed": 3, "name": "Baylor" }
        ],
        "date": "3/24",
        "time": "6:10 PM"
      },
      {
        "id": 4,
        "teams": [
          { "seed": 7, "name": "Dayton" },
          { "seed": 2, "name": "Arizona" }
        ],
        "date": "3/23",
        "time": "12:45 PM"
      },
      {
        "id": 5,
        "teams": [
          { "seed": 1, "name": "Purdue" },
          { "seed": 8, "name": "Utah St." }
        ],
        "date": "3/24",
        "time": "2:40 PM"
      },
      {
        "id": 6,
        "teams": [
          { "seed": 5, "name": "Gonzaga" },
          { "seed": 4, "name": "Kansas" }
        ],
        "date": "3/23",
        "time": "3:15 PM"
      },
      {
        "id": 7,
        "teams": [
          { "seed": 11, "name": "Oregon" },
          { "seed": 3, "name": "Creighton" }
        ],
        "date": "3/23",
        "time": "9:40 PM"
      },
      {
        "id": 8,
        "teams": [
          { "seed": 7, "name": "Texas" },
          { "seed": 2, "name": "Tennessee" }
        ],
        "date": "3/23",
        "time": "8:00 PM"
      },
      {
        "id": 9,
        "teams": [
          { "seed": 1, "name": "UConn" },
          { "seed": 9, "name": "Northwestern" }
        ],
        "date": "3/24",
        "time": "7:25 PM"
      },
      {
        "id": 10,
        "teams": [
          { "seed": 5, "name": "San Diego St." },
          { "seed": 13, "name": "Yale" }
        ],
        "date": "3/24",
        "time": "9:40 PM"
      },
      {
        "id": 11,
        "teams": [
          { "seed": 11, "name": "Duquesne" },
          { "seed": 3, "name": "Illinois" }
        ],
        "date": "3/23",
        "time": "8:40 PM"
      },
      {
        "id": 12,
        "teams": [
          { "seed": 7, "name": "Washington St." },
          { "seed": 2, "name": "Iowa St." }
        ],
        "date": "3/23",
        "time": "6:10 PM"
      },
      {
        "id": 13,
        "teams": [
          { "seed": 9, "name": "Texas A&M" },
          { "seed": 4, "name": "Houston" }
        ],
        "date": "3/24",
        "time": "8:40 PM"
      },
      {
        "id": 14,
        "teams": [
          { "seed": 4, "name": "Duke" },
          { "seed": 12, "name": "James Madison" }
        ],
        "date": "3/24",
        "time": "5:15 PM"
      },
      {
        "id": 15,
        "teams": [
          { "seed": 11, "name": "NC State" },
          { "seed": 14, "name": "Oakland" }
        ],
        "date": "3/23",
        "time": "7:10 PM"
      },
      {
        "id": 16,
        "teams": [
          { "seed": 10, "name": "Colorado" },
          { "seed": 2, "name": "Marquette" }
        ],
        "date": "3/24",
        "time": "12:10 PM"
      }
    ],
  },
];
