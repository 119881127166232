import React, { useEffect, useState } from "react";
import { usePagination, useSortBy, useTable } from "react-table";
import "./PlayerStatsTable.css";

const PlayerStatsTable = ({ year }) => {
	const [players, setPlayers] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchPlayerStats = async () => {
			try {
				const response = await fetch(
					`http://localhost:8000/playersAll/${year}`,
				);
				const data = await response.json();
				setPlayers(data);
			} catch (error) {
				console.error("Error fetching player stats:", error);
			} finally {
				setLoading(false);
			}
		};

		fetchPlayerStats();
	}, [year]);

	const columns = React.useMemo(
		() => [
			{ Header: "Name", accessor: "fullName" },
			{ Header: "Team", accessor: "classYr" },
			{
				Header: "Team Name",
				accessor: (row) => `${row.teamMarket} ${row.teamName}`,
			},
			{ Header: "Points", accessor: "ptsScored" },
		],
		[],
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		state: { pageIndex, pageSize },
	} = useTable(
		{
			columns,
			data: players,
			initialState: { pageIndex: 0 },
		},
		useSortBy,
		usePagination,
	);

	if (loading) return <p>Loading player stats...</p>;

	return (
		<div className="table-container">
			<h2>Player Stats for {year}</h2>
			<table {...getTableProps()}>
				<thead>
					{headerGroups.map((headerGroup) => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => (
								<th {...column.getHeaderProps(column.getSortByToggleProps())}>
									{column.render("Header")}
									<span>
										{column.isSorted
											? column.isSortedDesc
												? " 🔽"
												: " 🔼"
											: ""}
									</span>
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{page.map((row) => {
						prepareRow(row);
						return (
							<tr {...row.getRowProps()}>
								{row.cells.map((cell) => (
									<td {...cell.getCellProps()}>{cell.render("Cell")}</td>
								))}
							</tr>
						);
					})}
				</tbody>
			</table>
			<div>
				<button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
					{"<<"}
				</button>{" "}
				<button onClick={() => previousPage()} disabled={!canPreviousPage}>
					{"<"}
				</button>{" "}
				<button onClick={() => nextPage()} disabled={!canNextPage}>
					{">"}
				</button>{" "}
				<button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
					{">>"}
				</button>{" "}
				<span>
					Page{" "}
					<strong>
						{pageIndex + 1} of {pageOptions.length}
					</strong>{" "}
				</span>
				<span>
					| Go to page:{" "}
					<input
						type="number"
						defaultValue={pageIndex + 1}
						onChange={(e) => {
							const page = e.target.value ? Number(e.target.value) - 1 : 0;
							gotoPage(page);
						}}
						style={{ width: "100px" }}
					/>
				</span>{" "}
				<select
					value={pageSize}
					onChange={(e) => {
						setPageSize(Number(e.target.value));
					}}
				>
					{[10, 20, 30, 40, 50].map((pageSize) => (
						<option key={pageSize} value={pageSize}>
							Show {pageSize}
						</option>
					))}
				</select>
			</div>
		</div>
	);
};

export default PlayerStatsTable;
